class IframeManager {
    constructor(options) {
        this.iframes = new WeakMap();
        this.mutationCb = options.mutationCb;
    }
    addIframe(iframeEl) {
        this.iframes.set(iframeEl, true);
    }
    addLoadListener(cb) {
        this.loadListener = cb;
    }
    attachIframe(iframeEl, childSn) {
        var _a;
        this.mutationCb({
            adds: [
                {
                    parentId: iframeEl.__sn.id,
                    nextId: null,
                    node: childSn,
                },
            ],
            removes: [],
            texts: [],
            attributes: [],
            isAttachIframe: true,
        });
        (_a = this.loadListener) === null || _a === void 0 ? void 0 : _a.call(this, iframeEl);
    }
}

export { IframeManager };
