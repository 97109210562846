const callbackWrapper = (cb) => {
    const rrwebWrapped = (...rest) => {
        try {
            return cb(...rest);
        }
        catch (error) {
            try {
                error.__rrweb__ = true;
            }
            catch (_a) {
            }
            throw error;
        }
    };
    return rrwebWrapped;
};

export { callbackWrapper };
